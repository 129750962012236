$black: #090909;
$dark: #111111;
$background: #222222;
$foreground: #333333;
$selected: rgba(255,255,255,0.1);
$outline: rgba(0,0,0,0.2);

$odd: rgba(255,255,255,0.075);
$even: rgba(255,255,255,0.05);

$lighter2: rgba(255,255,255,0.10);
$lighter: rgba(255,255,255,0.05);

$darker: rgba(0,0,0,0.05);
$darker2: rgba(0,0,0,0.15);

$odd-light: rgba(0,0,0,0.05);
$even-light: rgba(0,0,0,0.15);

$background-1: #0e0e0e;
$background-2: #1f1f1f;
$background-3: #3a3a3a;

$highlight-4: #c0c0c0;
$highlight-5: #dadada;

$highlight-1: #dd0016;
$highlight-2: #00e4ec;
$highlight-3: #06bd00;

$purple: #8F3AF6;
$red: #bd0000;
$orange: #e07700;
$yellow: #e0d100;
$green: #06bd00;

a:hover {
  color: $highlight-2 !important; }

.glow-green {
  text-align: center;
  -webkit-animation: glow-green 1s ease-in-out infinite alternate;
  -moz-animation: glow-green 1s ease-in-out infinite alternate;
  animation: glow-green 1s ease-in-out infinite alternate; }

@keyframes glow-green {
  0% {
    text-shadow: 0 0 0.5em #06bd00aa; }
  100% {
    text-shadow: 0 0 1.0em #06bd00; } }

$text-1: white;
$text-2: rgba(255,255,255,0.87);
$text-3: rgba(255,255,255,0.7);

.text-white {
  color: $text-1; }

.text-title {
  color: $text-1; }

.text {
  color: $text-2; }

.text-red {
  color: $highlight-1; }

.text-blue {
  color: $highlight-2; }

.text-green {
  color: $highlight-3; }

.text-dull {
  color: $text-3; }

.text-disabled {
  color: grey; }

@font-face {
  font-family: "Remake";
  src: url('remake_font.otf'); }

.card-outline {
  border: 1px solid #303030;
  border-radius: 4px; }

.card-click {
  cursor: pointer;
  border-color: #303030;
  transition: border-color 0.2s, box-shadow 0.2s, background 0.2s;
  &:hover {
    border-color: #333333;
    box-shadow: 0px 0px 10px #333333; } }

.card-bg {
  background: #141414; }

.box-shadow {
  box-shadow: inset 0px 0px 10px $black; }

.color {
  border-color: $black;
  transition: border-color 0.2s, box-shadow 0.2s, background 0.2s;
  box-shadow: inset 0px 0px 4px $black;
  &:hover {
    border-color: $outline;
    box-shadow: inset 0px 0px 2px $black; } }

.card-shadow {
  box-shadow: 0px 0px 16px $black; }

.title {
  font-family: Remake;
  text-shadow: 0px 0px 4px black; }

.title-button {
  font-family: Remake;
  color: $text-3;
  text-shadow: 0px 0px 4px black;
  transition: color 0.25s, transform 0.25s;
  transform: scale(1.0);
  cursor: pointer;
  &:hover {
    color: $text-1;
    transform: scale(1.25); } }

// .border
//   border: 4px ridge grey

$grey: rgba(0,0,0,0.2);
$offwhite: rgba(0,0,0,0.1);
$glow: rgba(255,255,255,0.1);
$none: rgba(0,0,0,0);
$hover: rgba(255,255,255,0.6);

.slot {
  background: $black;
  border: 1px solid $glow; }

.slot-hover {
  cursor: pointer;
  background: transparent;
  // transition: background 0.05s
  &:hover {
    background: $hover !important; } }

.box-border {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 2px;
  background: radial-gradient($grey,$grey,$grey,$grey,$grey,$grey,$grey,$grey,$grey,$grey,$grey,rgba(255,255,255,0.2), rgba(255,255,255,0.4));
 }  // box-shadow: inset 0px 0px 10px white

.box-content {
  position: relative;
  margin: 1px;
  border-radius: 2px; }

.box-border:hover .box {
  opacity: 0; }

.box-border:hover .box-hover {
  opacity: 1; }

.box {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background: radial-gradient($none,$none,$none,$none,$none,$none,$none,$none,$none,$none,$none,$none,rgba(0,0,0,0.4), rgba(0,0,0,0.6));
  box-shadow: inset 0px 0px 10px black;
  transition: opacity 0.25s;
  opacity: 1;
  &.hover {
    opacity: 0; } }

.box-hover {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background: radial-gradient($glow,$glow,$glow,$glow,$glow,$glow,$glow,$glow,$glow,$glow,$glow,$glow,rgba(255,255,255,0.4), rgba(255,255,255,0.6));
  box-shadow: inset 0px 0px 10px black;
  transition: opacity 0.25s;
  opacity: 0;
  &.hover {
    opacity: 1; } }

.zfont {
  font-family: "Zombie"; }

.size-10 {
  font-size: 10em; }

.size-5 {
  font-size: 5em; }

.size-4 {
  font-size: 4em; }

.size-3 {
  font-size: 3em; }

.size-2half {
  font-size: 2.5em; }

.size-2 {
  font-size: 2em; }

.size-larger {
  font-size: 1.6em; }

.size-large {
  font-size: 1.2em; }

.size-small {
  font-size: 0.8em; }

.white-color {
  color: white; }

.text-color {
  color: rgba(255,255,255,0.87); }

.grey-color {
  color: grey; }

.fine-color {
  color: #00c703; }

.okay-color {
  color: #c8ff00; }

.caution-color {
  color: #e05a00; }

.danger-color {
  color: #9b0000; }

.black {
  background: $black; }

.black-tint {
  background: #090909dd; }

.dark {
  background: $dark; }

.dark-tint {
  background: #111111dd; }

.background {
  background: $background; }

.background-tint {
  background: #111111dd; }

.foreground {
  background: $foreground; }

.foreground-tint {
  background: #222222dd; }

.transparent {
  background: transparent; }

.rounded {
  border-radius: 2px;
  overflow: hidden; }

.round {
  border-radius: 50%; }

.visible {
  opacity: 1;
  transition: opacity 0.15s; }

.invisible {
  opacity: 0;
  transition: opacity 0.15s; }

.hidden {
  display: none; }

::-webkit-scrollbar {
  height: 12px;
  width: 12px; }

::-webkit-scrollbar-track {
  background: #333333;
  border: 1px solid #141414;
 }  // border-radius: 2px

::-webkit-scrollbar-thumb {
  background: rgba(200,200,200,0.5);
 }  // border-radius: 2px

::-webkit-scrollbar-thumb:hover {
  background: rgba(255,255,255,0.5); }

::-webkit-resizer {
  display: none; }

.small-scroll::-webkit-scrollbar {
  width: 0px; }

.small-scroll::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.5);
 }  // border-radius: 2px

.small-scroll::-webkit-scrollbar-thumb {
  background: rgba(200,200,200,0.5);
 }  // border-radius: 2px

.small-scroll::-webkit-scrollbar-thumb:hover {
  background: rgba(255,255,255,0.5); }

.small-scroll::-webkit-resizer {
  display: none; }

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #333333dd !important;
  padding: 5px !important;
  border-radius: 4px !important; }

.star {
  fill: none;
  stroke: red;
  stroke-width: 4px;
  stroke-dasharray: 1000px;
  stroke-dashoffset: 1000px;
  animation: move 3s linear infinite;
  animation-fill-mode: forwards; }

@keyframes move {
  100% {
    stroke-dashoffset: 0; } }

.wiki-row {
  height: 30px; }

.wiki-border {
  border-left: 4px solid transparent; }

.wiki-divider {
  height: 5px; }

.selectable {
  transition: background 0.2s;
  cursor: pointer;
  &:hover {
    background: #177ddc; } }

.selected {
  background: #177ddc; }

.search-input {
  padding: 0;
  box-shadow: inset 0px 0px 10px $black;
  background: #141414;
  border-color: #333333;
  > input {
    font-size: 1.1rem; } }

.hover-opacity:hover {
  opacity: 1; }

.caret-top {
  border-bottom: 8px solid transparent;
  border-left: 8px solid transparent;
  border-right: 8px solid #141414;
  transform: rotate(-45deg); }
