.absolute {
  position: absolute !important; }

.fixed {
  position: fixed !important; }

.relative {
  position: relative !important; }

.click {
  pointer-events: auto; }

.no-click {
  pointer-events: none; }

.fit-screen {
  width: 100vw;
  height: 100vh; }

.fit {
  width: 100%;
  height: 100%; }

.fit-x {
  width: 100%; }

.fit-y {
  height: 100%; }

.left {
  left: 0; }

.top {
  top: 0; }

.middle {
  transform: translate(-50%, -50%); }

.middle-x {
  transform: translate(-50%, 0%); }

.middle-y {
  transform: translate(0%, -50%); }

.right {
  right: 0; }

.bottom {
  bottom: 0; }

.inline {
  display: inline; }

.inline-block {
  display: inline-block; }

.block {
  display: block; }

.column {
  display: flex;
  flex-flow: column; }

.column-reverse {
  display: flex;
  flex-flow: column-reverse; }

.row {
  display: flex;
  flex-flow: row; }

.row-reverse {
  display: flex;
  flex-flow: row-reverse; }

.center {
  align-items: center;
  text-align: center;
  justify-content: center; }

.even {
  justify-content: space-evenly; }

.wrap {
  flex-wrap: wrap; }

.wrap-reverse {
  flex-wrap: wrap-reverse; }

.between {
  justify-content: space-between; }

.around {
  justify-content: space-around; }

.scale-down {
  object-fit: scale-down; }

.cover {
  object-fit: cover; }

.contain {
  object-fit: contain; }

.fill {
  object-fit: fill; }

.overflow {
  overflow: scroll; }

.overflow-x {
  overflow-x: scroll; }

.overflow-y {
  overflow-y: scroll; }

.overflow-hidden {
  overflow: hidden; }

.overflow-x-hidden {
  overflow-x: hidden; }

.overflow-y-hidden {
  overflow-y: hidden; }

.overflow-auto {
  overflow: auto; }

.overflow-x-auto {
  overflow-x: auto; }

.overflow-y-auto {
  overflow-y: auto; }

.overflow-visible {
  overflow: visible !important; }

.grow {
  flex-grow: 1; }
