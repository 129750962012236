.gaps {
  margin: 0.5em; }

.gaps-h-s {
  margin: 0em 0.25em; }

.gaps-h {
  margin: 0em 0.5em; }

.gaps-v {
  margin: 0.5em 0; }

.pad {
  padding: 0.5em; }

.pad-4px {
  padding: 4px; }

.pad-h-4px {
  padding: 0 4px; }

.pad-v-4px {
  padding: 4px 0; }

.pad-8px {
  padding: 8px; }

.pad-h-8px {
  padding: 0 8px; }

.pad-v-8px {
  padding: 8px 0; }

.pad-h {
  padding: 0 0.5em; }

.pad-v {
  padding: 0.5em 0; }

.pad-l {
  padding: 1em; }

.pad-h-l {
  padding: 0em 1em; }

.pad-v-l {
  padding: 1em 0em; }
